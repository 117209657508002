<template>
  <div v-loading="loaders.diets" class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Nutrition Guidelines</h1>
      <div class="d-flex justify-content-end">
        <router-link class="ml-2" :to="{ name: 'DietCategories'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Categories
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'DietSubCategories'}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Sub Categories
          </el-button>
        </router-link>
        <router-link class="ml-2 " :to="{ name: 'StoreDiet'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>

      </div>

    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.diets"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.diets"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.diets"
        >Deactivate
        </base-button>
      </div>
      <el-table-draggable
        handle=".handle"
        @drop="updateSortList"
      >
        <el-table
          class="table-responsive table-flush checklist-items-table checklist-table"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.diets"
          :row-class-name="row"
          @selection-change="dietsMultiSelect"
        >
          <el-table-column align="left" :class-name="btnDraggable" width="50">
            <template slot-scope="scope">
              <div class="handle" v-if="scope.row.id !==''">
                <img src="/img/icons/buttons/handle.svg" alt="+">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.diet_category ? scope.row.diet_category.name : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Sub Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.diet_sub_category ? scope.row.diet_sub_category.name : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="NAME" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.name}}</div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">
              <router-link :to="{name: 'ShowDiet', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
              <router-link :to="{name: 'EditDiet', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>

              <img
                class="pointer"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeDiet"
        @onRemoveMultiple="deleteDiet"
      />
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";


const RemoveModal = () => import(/* webpackChunkName: "dietRemoveModal" */ "@/components/Modals/RemoveModal.vue");
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
  name: "Diet",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    ElTableDraggable,
  },
  mounted() {
    this.fetchDiets()
  },
  data() {
    return {
      loaders: {
        diets: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        diets: [],
      },
      btnDraggable:'btnDraggable',
    };
  },
  methods: {

    fetchDiets() {
      let vm = this;
      vm.loaders.diets = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/diets')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.diets = response.data.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.diets = false);
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    dietsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      this.loaders.diets = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/diets/toggle-status`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchDiets()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.diets = false
        })
    },

    row({row, rowIndex}) {
      return "handle";
    },

    // This function accept the arguments and returns new order key
    assignNewOrderKey(oldOrderKey, prev, curr, next){
      let newOrderKey
      if (prev === '') {
        if (curr < next || next === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = next
        }
      } else if ( next === '') {
        if (curr > prev || prev === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = prev
        }
      } else if (curr < prev && curr < next) {
        newOrderKey = prev
      } else if (curr > prev && curr > next) {
        newOrderKey = next
      } else if (curr > prev && curr < next) {
        newOrderKey = oldOrderKey
      }
      return newOrderKey
    },

    // This function returns counts of 0 order keys
    orderCheck(list) {
      let orderCheckCounter = 0
      for (const item of list) {
        if (item.order_key === 0) {
          orderCheckCounter++
          if (orderCheckCounter > 1) { break }
        }
      }
      return orderCheckCounter;
    },

    //This function returns payload based on defined conditions
    assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event) {
      let payload
      if (orderCheckCounter > 1) {
        let orderKeyArray = []
        let order = 1;
        for (const item of event.list) {
          orderKeyArray.push({ id: item.id, order_key: order }) // Array having order set from Event
          order++
        }
        payload = { orderKeyArray }
      } else {
        payload = { id: event.targetObject.id, newOrderKey, oldOrderKey }
      }
      return payload
    },

    // This function updates the Order of the table list
    async updateSortList(event){
      let vm = this
      let oldOrderKey = event.targetObject.order_key
      let newOrderKey
      let currIndex = event.list.findIndex(item => item.id === event.targetObject.id)
      let curr = event.list.find(item => item.id === event.targetObject.id).order_key
      let prev = (event.list[currIndex - 1]) ? event.list[currIndex - 1].order_key : ''
      let next = (event.list[currIndex + 1]) ? event.list[currIndex + 1].order_key : ''

      // This function assigns new order key based on defined conditions
      newOrderKey = this.assignNewOrderKey(oldOrderKey, prev, curr, next)

      let orderCheckCounter = 0 // Counter to check number of 0's in order

      // Pass list and counter to get counts of 0 order key
      orderCheckCounter = this.orderCheck(event.list)

      // Check if item is not drag to other position
      if (newOrderKey === oldOrderKey && orderCheckCounter < 2) { return }

      let payload // Initializing payload

      //This function assigns the payload based on defined conditions
      payload = this.assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event)

      const dispatchPayload = {
        contentMenu: 'diets',
        payload
      }

      try{
        const response = await vm.$store.dispatch('_updateSortList', dispatchPayload); // Dispatch Api call to update sort list
        vm.response.diets = response.data.data
      }
      catch(error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Nutrition Guidelines',
          message: message
        });
      }
    },

    handleDelete(index, row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Nutrition Guideline.';
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these Nutrition Guidelines.';
      this.removeId = this.extractSelectedIds;
    },

    removeDiet(id) {
      this.deleteRequest(id)
    },

    deleteDiet() {
      this.deleteRequest(this.removeId)
    },

    deleteRequest(data) {
      this.loaders.diets = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/diets/destroy`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = [];
          this.fetchDiets();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.diets = false;
        })
    },

    changePage(page){
      this.request.pagination.page = page;
      this.fetchDiets();
    },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>


.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.btnDraggable {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  cursor: grabbing;
  margin-left: 0px;
}

.handle {
  cursor: grabbing;
}
</style>

<style>
.diets-sub-category-dropdown.el-button--primary{
  color: unset;
  border-color: unset;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 4px;
  border: none;
}

.diets-sub-category-dropdown.el-button--primary{
  border: none;
}

</style>

<style>
.el-dropdown-menu__item.diets-sub-category-dropdown-item a{
  font: normal normal normal 12px/17px Open Sans;
  color: #172B4D;
}

li.el-dropdown-menu__item.diets-sub-category-dropdown-item{
  padding: 0.25rem 1.75rem;
}

</style>
