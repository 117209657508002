<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{required: true}" v-model="request.memberPortalForm.name"
                  name="Name" label="Name*" placeholder=" Name"
                >
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input label="Status"
                            :rules="{required: true}"
                >
                  <el-select
                    class="w-100" label="Status"
                    v-model="request.memberPortalForm.status">
                    <el-option
                      v-for="option in dropdowns.status" :key="option.label"
                      :value="option.value" :label="option.label"
                      class="select-danger"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
                <div class="img-upload" :class="{'w-100':!imageExist}">
                  <label class="form-control-label" for="profileImage">Picture</label>
                  <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                         class="form-control file-upload" @change="onImageChange">
                  <span class="font-size-12px">(Recommended image size 250x250 px. Must be less than 500kb.)</span>
                </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2" :class="{'d-none':!imageExist}"
                  :src="request.memberPortalForm.imagePreview"
                  @load="handleLoad" @error="handleLoad"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col equal-height-columns">
                <label class="form-control-label">Content</label>
                <editor
                  v-model="request.memberPortalForm.content"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('memberPortalForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "MemberPortalForm",
  props: ['memberPortalItemToEdit', 'loaders', 'buttonText'],

  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        loaders: {
          image: false,
        },
        memberPortalForm: {
          name: '',
          image: null,
          picture: '',
          imagePreview: '',
          status: 1,
          content: '',
        },
      },
      dropdowns: {
        status: [
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive'},
        ],
      },
    }
  },
  watch: {
    memberPortalItemToEdit(newVal) {
      this.request.loaders.image = true
      this.request.memberPortalForm.name = newVal.name ?? '';
      this.request.memberPortalForm.content = newVal.content ?? '';
      this.request.memberPortalForm.status = newVal.status;
      this.request.memberPortalForm.imagePreview = newVal.picture
    }
  },
  methods: {

    onImageChange(e) {
      this.request.memberPortalForm.image = e.target.files[0];
    },

    handleKeyPressForEditor(value) {
      this.request.memberPortalForm.content = value;
    },

    async onSubmit(formName) {
      const isMemberPortalFormValid = await this.$refs['formValidator'].validate();

      if (isMemberPortalFormValid) {
        this.request.memberPortalForm.content = Helper.addCustomStylingToContent(this.request.memberPortalForm.content)
        if (this.$route.name === 'StoreMemberPortal') {
          this.$emit('storeMemberPortal', this.request.memberPortalForm);
        } else if (this.$route.name === 'EditMemberPortal') {
          this.$emit('updateMemberPortal', this.request.memberPortalForm);
        }
      } else {
        this.$notify.error({title: 'Validation Error', message: 'Please enter values in the form'});
      }
    },
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  computed: {
    imageExist() {
      return this.request.memberPortalForm.imagePreview !== ''
        && this.request.memberPortalForm.imagePreview
        && !this.request.memberPortalForm.image
    }
  },
}
</script>

<style scoped>

.img-size {
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload {
  width: calc(100% - 120px);
  display: inline-block;
}

.form-control.file-upload {
  height: calc(1.5em + 1.25rem + 13px);
  padding-top: calc(0.75rem + 3px);
}

</style>
