<template>
  <div>
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit {{ dynamicContentSection.name }}</h1>
    </div>
    <dynamic-content-section-item-form
      button-text="Update"
      :dynamic-section-item-to-edit="response.dynamicSection"
      @updateDynamicContentSectionItem="updateDynamicContentSectionItem"
      :dynamic-content-section="dynamicContentSection"
      :loaders="loaders"
    />
  </div>
</template>

<script>
import DynamicContentSectionItemForm from "./DynamicContentSectionItemForm"
import BackButton from "@/components/Router/BackButton"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin"

export default {
  name: "EditDynamicContentSectionItem",
  components: {
    DynamicContentSectionItemForm,
    BackButton,
  },
  data() {
    return {
      dynamicSectionItemId: this.$route.params.itemId ?? 0,
      response: {
        dynamicSection: {},
      },
      loaders: false,
      sectionId: this.$route.params.sectionId,
      dynamicContentSection: {
        name: 'Dynamic Section',
        id: 0,
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  mounted() {
    this.assignDynamicSectionObject()
    if (this.dynamicContentSection.name == '') this.getDynamicContentSection()
    this.getDynamicSectionListItem()
  },
  methods: {
    /**
     * Assign the dynamic section object
     */
    assignDynamicSectionObject() {
      this.dynamicContentSection = {
        id: this.$route.params.sectionId ?? 0,
        name: this.$route.params.sectionName ?? ''
      }
    },

    /**
     * Get request dynamic content section detail
     */
    getDynamicContentSection() {
      let vm = this
      vm.loaders = true
      const params = {id: this.sectionId}

      vm.$store.dispatch('dynamicContentSectionModule/_getRequestDynamicContentSection', params)
        .then(response => {
          vm.dynamicContentSection = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => {
          vm.loaders = false
        })
    },

    /**
     * Get required dynamic section list item
     */
    getDynamicSectionListItem() {
      let vm = this
      vm.loaders = true
      const payload = {
        dynamicSectionId: vm.dynamicSectionItemId
      }
      vm.$store.dispatch('dynamicContentSectionModule/_getDynamicSectionListItem', payload)
        .then((response) => {
          vm.response.dynamicSection = response.data.data
        }).catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: vm.dynamicContentSection.name,
          message: message
        })
      }).finally(() => {
        vm.loaders = false
      })
    },

    /**
     * Update the dynamic content section item
     * @param event
     */
    updateDynamicContentSectionItem(event) {
      let vm = this
      vm.loaders = true

      let formData = new FormData()
      formData.append('id', this.dynamicSectionItemId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })

      const config = {headers: {'content-type': 'multipart/form-data'}}
      const payload = {config, formData}

      vm.$store.dispatch('dynamicContentSectionModule/_updateDynamicSectionListingItem', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' updated successfully.'
          })
          vm.$router.back()
        }).catch(error => {
        const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Error',
          dangerouslyUseHTMLString: true,
          message: message
        })
      }).finally(() => {
        vm.loaders = false
      })
    }
  },
}
</script>

<style scoped>

</style>
