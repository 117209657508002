<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Patient Portal</h1>
    </div>
    <member-portal-form
      button-text="Update"
      :member-portal-item-to-edit="response.memberPortal"
      :loaders="loaders.memberPortal"
      @updateMemberPortal="updateMemberPortal"
    />
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import MemberPortalForm from "./MemberPortalForm";

export default {
  name: "EditMemberPortal",
  components: {BackButton, MemberPortalForm},
  data() {
    return {
      request: {
        id: this.$route.params.id,
      },
      response: {
        memberPortal: {},
      },
      loaders: {
        memberPortal: false
      },
    }
  },
  methods: {
    getMemberPortal() {
      let vm = this;
      vm.loaders.memberPortal = true;

      vm.$store.dispatch('memberPortal/_showMemberPortal', vm.request.id).then(response => {
        vm.response.memberPortal = response.data.data;
      }).catch(err => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({title: 'Error', message: message});
      }).finally(() => vm.loaders.memberPortal = false);
    },

    updateMemberPortal(event) {
      let vm = this;
      vm.loaders.memberPortal = true;
      var formData = new FormData();
      formData.append('id', this.request.id);
      _.each(event, (value, key) => {
        if (key !== 'imagePreview') {
          formData.append(key, value);
        }
      });
      vm.$store.dispatch('memberPortal/_updateMemberPortal', formData).then(response => {
        vm.$notify.success({title: 'Success', message: 'Patient Portal updated successfully. Always check to see how your changes look in the Baritastic app.'});
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        vm.$notify.error({title: 'Patient Portal', message: message});
        console.error(error.response);
      }).finally(() => vm.loaders.memberPortal = false);
    }
  },
  mounted() {
    this.getMemberPortal();
  }
}
</script>

<style>

.edit-patient .edit-patient-card-headings {
  text-align: left;
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-form-container .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.patient-detail-form-container .el-form-item__label {
  line-height: 20px;
  font-weight: 500 !important;
  color: black;
}

.patient-detail-form-container .el-form * {
  font: normal normal normal 14px/19px Open Sans;
}

.patient-detail-form-container .el-input.is-active .el-input__inner,
.patient-detail-form-container .el-input__inner:focus,
.patient-detail-form-container .el-select .el-input.is-focus .el-input__inner,
.patient-detail-form-container .el-select .el-input .el-input__inner:focus {
  border-color: #BDC4CC !important;
  box-shadow: 0 3px 2px #E9ECEF0D;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  margin-left: 4px;
}

</style>

