<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Patient Portal</h1>
    </div>
    <member-portal-form
      button-text="Save"
      :loaders="loaders.memberPortal"
      @storeMemberPortal="storeMemberPortal"
    />
  </div>
</template>

<script>
import MemberPortalForm from "./MemberPortalForm";
import BackButton from "@/components/Router/BackButton";

export default {
  components: {
    MemberPortalForm, BackButton
  },
  name: "StoreMemberPortal",
  mounted() {
    // this.getCategories();
  },
  data() {
    return {
      loaders: {
        memberPortal: false,
      },
    }
  },
  methods: {
    storeMemberPortal(event) {
      let vm = this;
      vm.loaders.memberPortal = true
      var formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      vm.$store.dispatch('memberPortal/_storeMemberPortal', formData).then(response => {
        vm.$notify.success({title: 'Success', message: 'Patient Portal created successfully.'});
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        vm.$notify.error({title: 'Patient Portal', message: message});
        console.error(error.response);
      }).finally(() => vm.loaders.memberPortal = false);
    },
  },

}
</script>
